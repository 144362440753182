import { Common } from "../paper/Common";

export class SVGCanvas{
    svg:any;
    container: any;
    svgGeometry: any;
    svgGraphics: any;
    width;
    height;
    init(container:any){
        this.svg = Common.getNode("svg", {});;
        //this.svg.setAttribute("style", "zoom:0.5");
        //this.svg.setAttribute("style", "width: 100%; height: 100%;");
        this.container = container;
        this.svgGeometry = Common.getNode("g", {class:'geometry'});
        this.svgGraphics = Common.getNode("g", {class:'graphics'});
        this.svg.appendChild(this.svgGraphics);
        this.svg.appendChild(this.svgGeometry);
        this.container.appendChild(this.svg);
    }

    resize(width, height){
        this.width = width;
        this.height = height;
        this.svg.setAttribute("width", width);
        this.svg.setAttribute("height", height);
        this.svg.setAttribute("viewBox", "0 0 "+width+" "+height);
    }

    clear(){
        this.svgGeometry.innerHTML = "";
        this.svgGraphics.innerHTML = "";

    }

    addImage(x,y,width,height,fileName,style){
        // let style = `style = "transform: scale(${imgWidth/x.width},${imgHeight/x.height});"`;
        // let imgsvg = `<image ${style} width="${x.width}" height="${x.height}" href="${fileName}" > </image >`;
        // svg.innerHTML = imgsvg  +svg.innerHTML;
        let g = Common.getNode("g", {});
      
        let imgsvg = `<image ${style} x="${x}" y="${y}" width="${width}" height="${height}" href="${fileName}" preserveAspectRatio="none" > </image >`;
        g.innerHTML = imgsvg;
        this.svgGraphics.appendChild(g);// = imgsvg  +svg.innerHTML;
    }

    addGeomerty(geom){
        this.svgGeometry.appendChild(geom);

    }
}