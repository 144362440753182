
import { SVGTransformer } from "../SVGTransformer";
import { Seam, Common, ISeam, ContentItem } from "./Common";

export interface IPrimitive{
    geometrySVG: string;
    isConst: Boolean;
    seams: ISeam[]; 

}

export class Primitive extends ContentItem{
    geometrySVG: string;
    isConst: Boolean;
    seams: Seam[]; 
    constructor (){
        super();
        this.seams = [];
        this.isConst = false;
        
    }

    translateGeometry(tx,ty){
        
        let g = Common.getNode("g", {});
        
            let newHtml = '';
            g.innerHTML = this.geometrySVG;
            let element = g.childNodes[0]; //paths[i];
            if (element.tagName.toLowerCase() == 'path') {
                let d = element.getAttribute("d");
                let newd = SVGTransformer.getTransformedPathDStr(d.trim(),0,0, tx, ty, 1, 1);
                                              
                let newPath = Common.getNode("path", {});
                for (var j = 0, attrs = element.attributes, l = attrs.length; j < l; j++) {
                    if (attrs.item(j).nodeName !== 'd' ) {
                        newPath.setAttribute(attrs.item(j).nodeName, attrs.item(j).nodeValue);
                    } else {
                        newPath.setAttribute("d", newd);
                    }
                }
                newHtml += newPath.outerHTML;
            }
            if (element.tagName.toLowerCase() == 'polyline') {
                let points = element.getAttribute("points");
                let newp = SVGTransformer.transformPolyline(points,0,0, tx, ty, 1, 1);
               
                let newPath = Common.getNode("polyline", {});
                for (var j = 0, attrs = element.attributes, l = attrs.length; j < l; j++) {
                    if (attrs.item(j).nodeName !== 'points') {
                        newPath.setAttribute(attrs.item(j).nodeName, attrs.item(j).nodeValue);
                    } else {
                        newPath.setAttribute("points", newp);
                    }
                }
                newHtml += newPath.outerHTML;
            }

            if (element.tagName.toLowerCase() == 'rect') {
                //log('rect', element);

                let x = parseFloat(element.getAttribute("x"));
                let y = parseFloat(element.getAttribute("y"));
                let width = element.getAttribute("width");
                let height = element.getAttribute("height");
                let newRect = Common.getNode("rect", {});
                for (var j = 0, attrs = element.attributes, l = attrs.length; j < l; j++) {
                    if (attrs.item(j).nodeName == 'x') {
                        newRect.setAttribute("x", x  + tx);
                    } else if (attrs.item(j).nodeName == 'y') {
                        newRect.setAttribute("y", y + ty);
                    } 
                    else {
                        newRect.setAttribute(attrs.item(j).nodeName, attrs.item(j).nodeValue);
                    }
                }
                newHtml += newRect.outerHTML;

            }

            if (element.tagName.toLowerCase() == 'line') {
                //log('rect', element);

                let x1 =parseFloat( element.getAttribute("x1"));
                let y1 = parseFloat(element.getAttribute("y1"));
                let x2 = parseFloat(element.getAttribute("x2"));
                let y2 = parseFloat(element.getAttribute("y2"));
                let newLine = Common.getNode("line", {});
                for (var j = 0, attrs = element.attributes, l = attrs.length; j < l; j++) {
                    if (attrs.item(j).nodeName == 'x1') {
                        newLine.setAttribute("x1", x1 + tx);
                    } else if (attrs.item(j).nodeName == 'y1') {
                        newLine.setAttribute("y1", y1 + ty);
                    } else if (attrs.item(j).nodeName == 'x2') {
                        newLine.setAttribute("x2", x2 + tx);
                    } else if (attrs.item(j).nodeName == 'y2') {
                        newLine.setAttribute("y2", y2 + ty);
                    }
                    else {
                        newLine.setAttribute(attrs.item(j).nodeName, attrs.item(j).nodeValue);
                    }
                }
                newHtml += newLine.outerHTML;

            }

            //if (!prim.isConst){
                for (let j = 0; j < this.seams.length; j++) {
                    const s = this.seams[j];
                    s.point.x = s.point.x + tx;
                    s.point.y = s.point.y + ty;
                    
                }
            //}
            this.geometrySVG = newHtml;
        //}
    }

}