import { PaperFace } from "../paper/PaperFace";

export class DAEParser {
    template;
    geometry = [];
    nodes = [];
    read = (file) => {
        let promise = new Promise((resolve, reject) => {
            fetch(file)
                .then(r => r.text())
                .then(str => (new window['DOMParser']()).parseFromString(str, "text/xml"))
                .then(_data => {
                    this.template = _data;

                    let geomerties = _data.querySelectorAll('library_geometries');

                    let scene = _data.querySelectorAll('library_visual_scenes>visual_scene');
                    console.log(geomerties, scene);


                    resolve(this);
                })


        });
        return promise;
    }

    addGeometry(face: PaperFace, id) {
        let f = face.face3d;
        //let wk = 1 
        //0.6052562 0.7610597 0.8269355 0.549865 0.8269355 0.7610597 0.6052562 0.7610597 0.6052563 0.549865 0.8269355 0.549865
        let textureCoords = face.uvcoords;// `${1-f.p1.x/w} ${f.p1.y/h} ${1-f.p2.x/w} ${f.p2.y/h} ${1-f.p3.x/w} ${f.p3.y/h} ${1-f.p1.x/w} ${f.p1.y/h} ${1-f.p0.x/w} ${f.p0.y/h} ${1-f.p2.x/w} ${f.p2.y/h}`;
        console.log('textureCoords', textureCoords);
        let coords = `${f.p0.x} ${f.p0.y} ${f.p0.z} ${f.p1.x} ${f.p1.y} ${f.p1.z} ${f.p2.x} ${f.p2.y} ${f.p2.z} ${f.p3.x} ${f.p3.y} ${f.p3.z}`;

        //textured
        let mesh = `
    <geometry id="Plane_${id}-mesh" name="Plane.${id}">
    <mesh>
      <source id="Plane_${id}-mesh-positions">
        <float_array id="Plane_${id}-mesh-positions-array" count="12">${coords}</float_array>
        <technique_common>
          <accessor source="#Plane_${id}-mesh-positions-array" count="4" stride="3">
            <param name="X" type="float"/>
            <param name="Y" type="float"/>
            <param name="Z" type="float"/>
          </accessor>
        </technique_common>
      </source>
      <source id="Plane_${id}-mesh-normals">
        <float_array id="Plane_${id}-mesh-normals-array" count="6">${f.normal.x} ${f.normal.y} ${f.normal.z}</float_array>
        <technique_common>
          <accessor source="#Plane_${id}-mesh-normals-array" count="2" stride="3">
            <param name="X" type="float"/>
            <param name="Y" type="float"/>
            <param name="Z" type="float"/>
          </accessor>
        </technique_common>
      </source>
      <source id="Plane_${id}-mesh-map-0">
        <float_array id="Plane_${id}-mesh-map-0-array" count="12">${textureCoords}</float_array>
        <technique_common>
          <accessor source="#Plane_${id}-mesh-map-0-array" count="6" stride="2">
            <param name="S" type="float"/>
            <param name="T" type="float"/>
          </accessor>
        </technique_common>
      </source>
      <vertices id="Plane_${id}-mesh-vertices">
        <input semantic="POSITION" source="#Plane_${id}-mesh-positions"/>
      </vertices>
      <triangles material="Material_001-material" count="2">
        <input semantic="VERTEX" source="#Plane_${id}-mesh-vertices" offset="0"/>
        <input semantic="NORMAL" source="#Plane_${id}-mesh-normals" offset="1"/>
        <input semantic="TEXCOORD" source="#Plane_${id}-mesh-map-0" offset="2" set="0"/>
        <p>0 0 0 3 0 1 2 0 2 0 0 3 1 0 4 3 0 5</p>
      </triangles>
    </mesh>
    <extra><technique profile="MAYA"><double_sided>0</double_sided></technique></extra>
  </geometry>`;
        let idback = id + '_b';
        let coordsb = `${face.face3dback.p0.x} ${face.face3dback.p0.y} ${face.face3dback.p0.z} ${face.face3dback.p1.x} ${face.face3dback.p1.y} ${face.face3dback.p1.z} ${face.face3dback.p2.x} ${face.face3dback.p2.y} ${face.face3dback.p2.z} ${face.face3dback.p3.x} ${face.face3dback.p3.y} ${face.face3dback.p3.z}`;
        mesh += `
    <geometry id="Plane_${idback}-mesh" name="Plane.${idback}">
    <mesh>
      <source id="Plane_${idback}-mesh-positions">
        <float_array id="Plane_${idback}-mesh-positions-array" count="12">${coordsb}</float_array>
        <technique_common>
          <accessor source="#Plane_${idback}-mesh-positions-array" count="4" stride="3">
            <param name="X" type="float"/>
            <param name="Y" type="float"/>
            <param name="Z" type="float"/>
          </accessor>
        </technique_common>
      </source>
      <source id="Plane_${idback}-mesh-normals">
        <float_array id="Plane_${idback}-mesh-normals-array" count="6">${face.face3dback.normal.x} ${face.face3dback.normal.y} ${face.face3dback.normal.z}</float_array>
        <technique_common>
          <accessor source="#Plane_${idback}-mesh-normals-array" count="2" stride="3">
            <param name="X" type="float"/>
            <param name="Y" type="float"/>
            <param name="Z" type="float"/>
          </accessor>
        </technique_common>
      </source>
      <source id="Plane_${idback}-mesh-map-0">
        <float_array id="Plane_${idback}-mesh-map-0-array" count="12">${textureCoords}</float_array>
        <technique_common>
          <accessor source="#Plane_${idback}-mesh-map-0-array" count="6" stride="2">
            <param name="S" type="float"/>
            <param name="T" type="float"/>
          </accessor>
        </technique_common>
      </source>
      <vertices id="Plane_${idback}-mesh-vertices">
        <input semantic="POSITION" source="#Plane_${idback}-mesh-positions"/>
      </vertices>
      <triangles material="Material_001_b-material" count="2">
        <input semantic="VERTEX" source="#Plane_${idback}-mesh-vertices" offset="0"/>
        <input semantic="NORMAL" source="#Plane_${idback}-mesh-normals" offset="1"/>
        <input semantic="TEXCOORD" source="#Plane_${idback}-mesh-map-0" offset="2" set="0"/>
        <p>0 0 0 3 0 1 2 0 2 0 0 3 1 0 4 3 0 5</p>
      </triangles>
    </mesh>
    <extra><technique profile="MAYA"><double_sided>0</double_sided></technique></extra>
  </geometry>`;


        let node = ` <node id="Plane_${id}" name="Plane_${id}" type="NODE">
      
      <instance_geometry url="#Plane_${id}-mesh" name="Plane_${id}">
        <bind_material>
          <technique_common>
            <instance_material symbol="Material_001-material" target="#Material_001-material"/>
          </technique_common>
        </bind_material>
      </instance_geometry>
    </node>

    <node id="Plane_${idback}" name="Plane_${idback}" type="NODE">
      
      <instance_geometry url="#Plane_${idback}-mesh" name="Plane_${idback}">
        <bind_material>
          <technique_common>
            <instance_material symbol="Material_001_b-material" target="#Material_001_b-material"/>
          </technique_common>
        </bind_material>
      </instance_geometry>
    </node>

`
        this.geometry.push(mesh);
        this.nodes.push(node);
    }


    daeTemplate = (path,file1,file2) => {

        let geometry = this.geometry.join("");
        let nodes = this.nodes.join("");

        let tpl = `<?xml version="1.0" encoding="utf-8"?>
        <COLLADA xmlns="http://www.collada.org/2005/11/COLLADASchema" version="1.4.1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
          <asset>
            <contributor>
              <author>Blender User</author>
              <authoring_tool>Blender 2.79.0 commit date:2018-03-22, commit time:14:10, hash:f4dc9f9d68b</authoring_tool>
            </contributor>
            <created>2019-03-11T14:23:18</created>
            <modified>2019-03-11T14:23:18</modified>
            <unit name="meter" meter="1"/>
            <up_axis>Z_UP</up_axis>
          </asset>
          <library_lights>
            <light id="Point-light" name="Point">
            <technique_common>
                <point>
                <color sid="color">0.5 0.5 0.5</color>
                <constant_attenuation>1</constant_attenuation>
                <linear_attenuation>0</linear_attenuation>
                <quadratic_attenuation>0.001599967</quadratic_attenuation>
                </point>
            </technique_common>
            <extra>
                <technique profile="blender">
                <type sid="type" type="int">0</type>
                <flag sid="flag" type="int">0</flag>
                <mode sid="mode" type="int">1</mode>
                <gamma sid="blender_gamma" type="float">1</gamma>
                <red sid="red" type="float">1</red>
                <green sid="green" type="float">1</green>
                <blue sid="blue" type="float">1</blue>
                <shadow_r sid="blender_shadow_r" type="float">0</shadow_r>
                <shadow_g sid="blender_shadow_g" type="float">0</shadow_g>
                <shadow_b sid="blender_shadow_b" type="float">0</shadow_b>
                <energy sid="blender_energy" type="float">1</energy>
                <dist sid="blender_dist" type="float">25</dist>
                <spotsize sid="spotsize" type="float">45</spotsize>
                <spotblend sid="spotblend" type="float">0.15</spotblend>
                <halo_intensity sid="blnder_halo_intensity" type="float">1</halo_intensity>
                <att1 sid="att1" type="float">0</att1>
                <att2 sid="att2" type="float">1</att2>
                <falloff_type sid="falloff_type" type="int">2</falloff_type>
                <clipsta sid="clipsta" type="float">0.5</clipsta>
                <clipend sid="clipend" type="float">40</clipend>
                <bias sid="bias" type="float">1</bias>
                <soft sid="soft" type="float">3</soft>
                <compressthresh sid="compressthresh" type="float">0.04999995</compressthresh>
                <bufsize sid="bufsize" type="int">512</bufsize>
                <samp sid="samp" type="int">3</samp>
                <buffers sid="buffers" type="int">1</buffers>
                <filtertype sid="filtertype" type="int">0</filtertype>
                <bufflag sid="bufflag" type="int">0</bufflag>
                <buftype sid="buftype" type="int">2</buftype>
                <ray_samp sid="ray_samp" type="int">1</ray_samp>
                <ray_sampy sid="ray_sampy" type="int">1</ray_sampy>
                <ray_sampz sid="ray_sampz" type="int">1</ray_sampz>
                <ray_samp_type sid="ray_samp_type" type="int">0</ray_samp_type>
                <area_shape sid="area_shape" type="int">0</area_shape>
                <area_size sid="area_size" type="float">0.1</area_size>
                <area_sizey sid="area_sizey" type="float">0.1</area_sizey>
                <area_sizez sid="area_sizez" type="float">0.1</area_sizez>
                <adapt_thresh sid="adapt_thresh" type="float">0.000999987</adapt_thresh>
                <ray_samp_method sid="ray_samp_method" type="int">1</ray_samp_method>
                <shadhalostep sid="shadhalostep" type="int">0</shadhalostep>
                <sun_effect_type sid="sun_effect_type" type="int">0</sun_effect_type>
                <skyblendtype sid="skyblendtype" type="int">1</skyblendtype>
                <horizon_brightness sid="horizon_brightness" type="float">1</horizon_brightness>
                <spread sid="spread" type="float">1</spread>
                <sun_brightness sid="sun_brightness" type="float">1</sun_brightness>
                <sun_size sid="sun_size" type="float">1</sun_size>
                <backscattered_light sid="backscattered_light" type="float">1</backscattered_light>
                <sun_intensity sid="sun_intensity" type="float">1</sun_intensity>
                <atm_turbidity sid="atm_turbidity" type="float">2</atm_turbidity>
                <atm_extinction_factor sid="atm_extinction_factor" type="float">1</atm_extinction_factor>
                <atm_distance_factor sid="atm_distance_factor" type="float">1</atm_distance_factor>
                <skyblendfac sid="skyblendfac" type="float">1</skyblendfac>
                <sky_exposure sid="sky_exposure" type="float">1</sky_exposure>
                <sky_colorspace sid="sky_colorspace" type="int">2</sky_colorspace>
                </technique>
            </extra>
            </light>
            <light id="Point_001-light" name="Point.001">
            <technique_common>
                <point>
                <color sid="color">0.15 0.15 0.22</color>
                <constant_attenuation>1</constant_attenuation>
                <linear_attenuation>0</linear_attenuation>
                <quadratic_attenuation>0.001599967</quadratic_attenuation>
                </point>
            </technique_common>
            <extra>
                <technique profile="blender">
                <type sid="type" type="int">0</type>
                <flag sid="flag" type="int">0</flag>
                <mode sid="mode" type="int">1</mode>
                <gamma sid="blender_gamma" type="float">1</gamma>
                <red sid="red" type="float">1</red>
                <green sid="green" type="float">1</green>
                <blue sid="blue" type="float">1</blue>
                <shadow_r sid="blender_shadow_r" type="float">0</shadow_r>
                <shadow_g sid="blender_shadow_g" type="float">0</shadow_g>
                <shadow_b sid="blender_shadow_b" type="float">0</shadow_b>
                <energy sid="blender_energy" type="float">1</energy>
                <dist sid="blender_dist" type="float">25</dist>
                <spotsize sid="spotsize" type="float">45</spotsize>
                <spotblend sid="spotblend" type="float">0.15</spotblend>
                <halo_intensity sid="blnder_halo_intensity" type="float">1</halo_intensity>
                <att1 sid="att1" type="float">0</att1>
                <att2 sid="att2" type="float">1</att2>
                <falloff_type sid="falloff_type" type="int">2</falloff_type>
                <clipsta sid="clipsta" type="float">0.5</clipsta>
                <clipend sid="clipend" type="float">40</clipend>
                <bias sid="bias" type="float">1</bias>
                <soft sid="soft" type="float">3</soft>
                <compressthresh sid="compressthresh" type="float">0.04999995</compressthresh>
                <bufsize sid="bufsize" type="int">512</bufsize>
                <samp sid="samp" type="int">3</samp>
                <buffers sid="buffers" type="int">1</buffers>
                <filtertype sid="filtertype" type="int">0</filtertype>
                <bufflag sid="bufflag" type="int">0</bufflag>
                <buftype sid="buftype" type="int">2</buftype>
                <ray_samp sid="ray_samp" type="int">1</ray_samp>
                <ray_sampy sid="ray_sampy" type="int">1</ray_sampy>
                <ray_sampz sid="ray_sampz" type="int">1</ray_sampz>
                <ray_samp_type sid="ray_samp_type" type="int">0</ray_samp_type>
                <area_shape sid="area_shape" type="int">0</area_shape>
                <area_size sid="area_size" type="float">0.1</area_size>
                <area_sizey sid="area_sizey" type="float">0.1</area_sizey>
                <area_sizez sid="area_sizez" type="float">0.1</area_sizez>
                <adapt_thresh sid="adapt_thresh" type="float">0.000999987</adapt_thresh>
                <ray_samp_method sid="ray_samp_method" type="int">1</ray_samp_method>
                <shadhalostep sid="shadhalostep" type="int">0</shadhalostep>
                <sun_effect_type sid="sun_effect_type" type="int">0</sun_effect_type>
                <skyblendtype sid="skyblendtype" type="int">1</skyblendtype>
                <horizon_brightness sid="horizon_brightness" type="float">1</horizon_brightness>
                <spread sid="spread" type="float">1</spread>
                <sun_brightness sid="sun_brightness" type="float">1</sun_brightness>
                <sun_size sid="sun_size" type="float">1</sun_size>
                <backscattered_light sid="backscattered_light" type="float">1</backscattered_light>
                <sun_intensity sid="sun_intensity" type="float">1</sun_intensity>
                <atm_turbidity sid="atm_turbidity" type="float">2</atm_turbidity>
                <atm_extinction_factor sid="atm_extinction_factor" type="float">1</atm_extinction_factor>
                <atm_distance_factor sid="atm_distance_factor" type="float">1</atm_distance_factor>
                <skyblendfac sid="skyblendfac" type="float">1</skyblendfac>
                <sky_exposure sid="sky_exposure" type="float">1</sky_exposure>
                <sky_colorspace sid="sky_colorspace" type="int">2</sky_colorspace>
                </technique>
            </extra>
            </light>
            <light id="Point_002-light" name="Point.002">
            <technique_common>
                <point>
                <color sid="color">0.1 0.1 0.1</color>
                <constant_attenuation>1</constant_attenuation>
                <linear_attenuation>0</linear_attenuation>
                <quadratic_attenuation>0.001599967</quadratic_attenuation>
                </point>
            </technique_common>
            <extra>
                <technique profile="blender">
                <type sid="type" type="int">0</type>
                <flag sid="flag" type="int">0</flag>
                <mode sid="mode" type="int">1</mode>
                <gamma sid="blender_gamma" type="float">1</gamma>
                <red sid="red" type="float">1</red>
                <green sid="green" type="float">1</green>
                <blue sid="blue" type="float">1</blue>
                <shadow_r sid="blender_shadow_r" type="float">0</shadow_r>
                <shadow_g sid="blender_shadow_g" type="float">0</shadow_g>
                <shadow_b sid="blender_shadow_b" type="float">0</shadow_b>
                <energy sid="blender_energy" type="float">1</energy>
                <dist sid="blender_dist" type="float">25</dist>
                <spotsize sid="spotsize" type="float">45</spotsize>
                <spotblend sid="spotblend" type="float">0.15</spotblend>
                <halo_intensity sid="blnder_halo_intensity" type="float">1</halo_intensity>
                <att1 sid="att1" type="float">0</att1>
                <att2 sid="att2" type="float">1</att2>
                <falloff_type sid="falloff_type" type="int">2</falloff_type>
                <clipsta sid="clipsta" type="float">0.5</clipsta>
                <clipend sid="clipend" type="float">40</clipend>
                <bias sid="bias" type="float">1</bias>
                <soft sid="soft" type="float">3</soft>
                <compressthresh sid="compressthresh" type="float">0.04999995</compressthresh>
                <bufsize sid="bufsize" type="int">512</bufsize>
                <samp sid="samp" type="int">3</samp>
                <buffers sid="buffers" type="int">1</buffers>
                <filtertype sid="filtertype" type="int">0</filtertype>
                <bufflag sid="bufflag" type="int">0</bufflag>
                <buftype sid="buftype" type="int">2</buftype>
                <ray_samp sid="ray_samp" type="int">1</ray_samp>
                <ray_sampy sid="ray_sampy" type="int">1</ray_sampy>
                <ray_sampz sid="ray_sampz" type="int">1</ray_sampz>
                <ray_samp_type sid="ray_samp_type" type="int">0</ray_samp_type>
                <area_shape sid="area_shape" type="int">0</area_shape>
                <area_size sid="area_size" type="float">0.1</area_size>
                <area_sizey sid="area_sizey" type="float">0.1</area_sizey>
                <area_sizez sid="area_sizez" type="float">0.1</area_sizez>
                <adapt_thresh sid="adapt_thresh" type="float">0.000999987</adapt_thresh>
                <ray_samp_method sid="ray_samp_method" type="int">1</ray_samp_method>
                <shadhalostep sid="shadhalostep" type="int">0</shadhalostep>
                <sun_effect_type sid="sun_effect_type" type="int">0</sun_effect_type>
                <skyblendtype sid="skyblendtype" type="int">1</skyblendtype>
                <horizon_brightness sid="horizon_brightness" type="float">1</horizon_brightness>
                <spread sid="spread" type="float">1</spread>
                <sun_brightness sid="sun_brightness" type="float">1</sun_brightness>
                <sun_size sid="sun_size" type="float">1</sun_size>
                <backscattered_light sid="backscattered_light" type="float">1</backscattered_light>
                <sun_intensity sid="sun_intensity" type="float">1</sun_intensity>
                <atm_turbidity sid="atm_turbidity" type="float">2</atm_turbidity>
                <atm_extinction_factor sid="atm_extinction_factor" type="float">1</atm_extinction_factor>
                <atm_distance_factor sid="atm_distance_factor" type="float">1</atm_distance_factor>
                <skyblendfac sid="skyblendfac" type="float">1</skyblendfac>
                <sky_exposure sid="sky_exposure" type="float">1</sky_exposure>
                <sky_colorspace sid="sky_colorspace" type="int">2</sky_colorspace>
                </technique>
            </extra>
            </light>
            <light id="Point_003-light" name="Point.003">
            <technique_common>
                <point>
                <color sid="color">0.22 0.15 0.15</color>
                <constant_attenuation>1</constant_attenuation>
                <linear_attenuation>0</linear_attenuation>
                <quadratic_attenuation>0.001599967</quadratic_attenuation>
                </point>
            </technique_common>
            <extra>
                <technique profile="blender">
                <type sid="type" type="int">0</type>
                <flag sid="flag" type="int">0</flag>
                <mode sid="mode" type="int">1</mode>
                <gamma sid="blender_gamma" type="float">1</gamma>
                <red sid="red" type="float">1</red>
                <green sid="green" type="float">1</green>
                <blue sid="blue" type="float">1</blue>
                <shadow_r sid="blender_shadow_r" type="float">0</shadow_r>
                <shadow_g sid="blender_shadow_g" type="float">0</shadow_g>
                <shadow_b sid="blender_shadow_b" type="float">0</shadow_b>
                <energy sid="blender_energy" type="float">1</energy>
                <dist sid="blender_dist" type="float">25</dist>
                <spotsize sid="spotsize" type="float">45</spotsize>
                <spotblend sid="spotblend" type="float">0.15</spotblend>
                <halo_intensity sid="blnder_halo_intensity" type="float">1</halo_intensity>
                <att1 sid="att1" type="float">0</att1>
                <att2 sid="att2" type="float">1</att2>
                <falloff_type sid="falloff_type" type="int">2</falloff_type>
                <clipsta sid="clipsta" type="float">0.5</clipsta>
                <clipend sid="clipend" type="float">40</clipend>
                <bias sid="bias" type="float">1</bias>
                <soft sid="soft" type="float">3</soft>
                <compressthresh sid="compressthresh" type="float">0.04999995</compressthresh>
                <bufsize sid="bufsize" type="int">512</bufsize>
                <samp sid="samp" type="int">3</samp>
                <buffers sid="buffers" type="int">1</buffers>
                <filtertype sid="filtertype" type="int">0</filtertype>
                <bufflag sid="bufflag" type="int">0</bufflag>
                <buftype sid="buftype" type="int">2</buftype>
                <ray_samp sid="ray_samp" type="int">1</ray_samp>
                <ray_sampy sid="ray_sampy" type="int">1</ray_sampy>
                <ray_sampz sid="ray_sampz" type="int">1</ray_sampz>
                <ray_samp_type sid="ray_samp_type" type="int">0</ray_samp_type>
                <area_shape sid="area_shape" type="int">0</area_shape>
                <area_size sid="area_size" type="float">0.1</area_size>
                <area_sizey sid="area_sizey" type="float">0.1</area_sizey>
                <area_sizez sid="area_sizez" type="float">0.1</area_sizez>
                <adapt_thresh sid="adapt_thresh" type="float">0.000999987</adapt_thresh>
                <ray_samp_method sid="ray_samp_method" type="int">1</ray_samp_method>
                <shadhalostep sid="shadhalostep" type="int">0</shadhalostep>
                <sun_effect_type sid="sun_effect_type" type="int">0</sun_effect_type>
                <skyblendtype sid="skyblendtype" type="int">1</skyblendtype>
                <horizon_brightness sid="horizon_brightness" type="float">1</horizon_brightness>
                <spread sid="spread" type="float">1</spread>
                <sun_brightness sid="sun_brightness" type="float">1</sun_brightness>
                <sun_size sid="sun_size" type="float">1</sun_size>
                <backscattered_light sid="backscattered_light" type="float">1</backscattered_light>
                <sun_intensity sid="sun_intensity" type="float">1</sun_intensity>
                <atm_turbidity sid="atm_turbidity" type="float">2</atm_turbidity>
                <atm_extinction_factor sid="atm_extinction_factor" type="float">1</atm_extinction_factor>
                <atm_distance_factor sid="atm_distance_factor" type="float">1</atm_distance_factor>
                <skyblendfac sid="skyblendfac" type="float">1</skyblendfac>
                <sky_exposure sid="sky_exposure" type="float">1</sky_exposure>
                <sky_colorspace sid="sky_colorspace" type="int">2</sky_colorspace>
                </technique>
            </extra>
            </light>
          </library_lights>
          <library_images>
            <image id="viz_1_texture_png" name="viz_1_texture_png">
              <init_from>${path}${file1}</init_from>
            </image>
            <image id="viz_1_texture_b_png" name="viz_1_texture_png_b">
              <init_from>${path}${file2}</init_from>
            </image>
          </library_images>
          <library_effects>
            <effect id="Material_001-effect">
              <profile_COMMON>
                <newparam sid="viz_1_texture_png-surface">
                  <surface type="2D">
                    <init_from>viz_1_texture_png</init_from>
                  </surface>
                </newparam>
                <newparam sid="viz_1_texture_png-sampler">
                  <sampler2D>
                    <source>viz_1_texture_png-surface</source>
                  </sampler2D>
                </newparam>
                <technique sid="common">
                  <phong>
                    <emission>
                      <color sid="emission">0 0 0 1</color>
                    </emission>
                    <ambient>
                      <color sid="ambient">0 0 0 1</color>
                    </ambient>
                    <diffuse>
                      <texture texture="viz_1_texture_png-sampler"/>
                    </diffuse>
                    <specular>
                      <color sid="specular">0.5 0.5 0.5 1</color>
                    </specular>
                    <shininess>
                      <float sid="shininess">10</float>
                    </shininess>
                    <transparent opaque="A_ONE">
                        <texture texture="viz_1_texture_png-sampler"/>
                    </transparent>
                    <index_of_refraction>
                      <float sid="index_of_refraction">1</float>
                    </index_of_refraction>
                  </phong>
                </technique>
                
              </profile_COMMON>
             
            </effect>
            <effect id="Material_001_b-effect">
              <profile_COMMON>
                <newparam sid="viz_1_texture_b_png-surface">
                  <surface type="2D">
                    <init_from>viz_1_texture_b_png</init_from>
                  </surface>
                </newparam>
                <newparam sid="viz_1_texture_b_png-sampler">
                  <sampler2D>
                    <source>viz_1_texture_b_png-surface</source>
                  </sampler2D>
                </newparam>
                <technique sid="common">
                  <phong>
                    <emission>
                      <color sid="emission">0 0 0 1</color>
                    </emission>
                    <ambient>
                      <color sid="ambient">0 0 0 1</color>
                    </ambient>
                    <diffuse>
                      <texture texture="viz_1_texture_b_png-sampler"/>
                    </diffuse>
                    <specular>
                      <color sid="specular">0.5 0.5 0.5 1</color>
                    </specular>
                    <shininess>
                      <float sid="shininess">10</float>
                    </shininess>
                    <transparent opaque="A_ONE">
                        <texture texture="viz_1_texture_b_png-sampler"/>
                    </transparent>
                    <index_of_refraction>
                      <float sid="index_of_refraction">1</float>
                    </index_of_refraction>
                  </phong>
                </technique>
                
              </profile_COMMON>
             
            </effect>
          </library_effects>
          <library_materials>
            <material id="Material_001-material" name="Material_001">
              <instance_effect url="#Material_001-effect"/>
            </material>
            <material id="Material_001_b-material" name="Material_001_b">
              <instance_effect url="#Material_001_b-effect"/>
            </material>
          </library_materials>
          <library_geometries>
            ${geometry}
          </library_geometries>
          <library_controllers/>
          <library_visual_scenes>
            <visual_scene id="Scene" name="Scene">
              
              ${nodes}
              <node id="Point" name="Point" type="NODE">
                <matrix sid="transform">1 0 0 -4.259 0 1 0 4.730 0 0 1 7.403 0 0 0 1</matrix>
                <instance_light url="#Point-light"/>
              </node>
              <node id="Point_001" name="Point_001" type="NODE">
                <matrix sid="transform">1 0 0 6.037979 0 1 0 -4.720824 0 0 1 -5.747825 0 0 0 1</matrix>
                <instance_light url="#Point_001-light"/>
              </node>
              <node id="Point_002" name="Point_002" type="NODE">
                <matrix sid="transform">1 0 0 -3.322656 0 1 0 5.129631 0 0 1 -3.11037 0 0 0 1</matrix>
                <instance_light url="#Point_002-light"/>
              </node>
              <node id="Point_003" name="Point_003" type="NODE">
                <matrix sid="transform">1 0 0 7.758499 0 1 0 -4.720824 0 0 1 7.435061 0 0 0 1</matrix>
                <instance_light url="#Point_003-light"/>
              </node>

            </visual_scene>
          </library_visual_scenes>
          <scene>
            <instance_visual_scene url="#Scene"/>
          </scene>
        </COLLADA>

        `
        return tpl;
    }
}