export class Globals{
   // static API_URL = "http://192.168.0.86:3005/";
   // static API_URL = "http://zakaz.fastcolor.ru:3005/";
  
   //localhost
//    static API_URL = "http://192.168.0.86:3005/";
//    static MODELS_PATH = "http://192.168.0.86/korobka3/korobka2/models/"
//    static SAVED_PATH = "http://192.168.0.86//korobka3_bk/saved/";
//    static UPLOAD_PATH = "http://192.168.0.86//korobka3_bk/saved/";

   //убрать 
   // static SAVED_PATH = "http://192.168.0.86:/korobka3/saved/";
   //static UPLOAD_PATH = "http://192.168.0.86:/korobka3/uploads/";
   
   //fastcolor
   static config = null;
   static CONFIG_URL = "../../config.json";
//    static API_URL = "http://192.168.0.7:3005/";
//    static MODELS_PATH = "http://192.168.0.7/spm_test/korobki/models/"
//    static SAVED_PATH = "http://192.168.0.7/proverka/automation/mainflow/2output/";
//    static UPLOAD_PATH = "http://192.168.0.7/proverka/automation/xkorobkicache/";
}