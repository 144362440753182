export class SVGTransformer{
    static getTransformedPathDStr(oldPathDStr, pathBaseX, pathBaseY, pathTranslX, pathTranslY ,scaleX, scaleY) {

        // constants to help keep track of the types of SVG commands in the path
        var BOTH_X_AND_Y = 1;
        var JUST_X = 2;
        var JUST_Y = 3;
        var NONE = 4;
        var ELLIPTICAL_ARC = 5;
        var ABSOLUTE = 6;
        var RELATIVE = 7;
    
        // two parallel arrays, with each element being one component of the
        // "d" attribute of the SVG path, with one component being either
        // an instruction (e.g. "M" for moveto, etc.) or numerical value
        // for either an x or y coordinate
        if (oldPathDStr[1] !== ' ') oldPathDStr = oldPathDStr.replace(/(\r\n|\n|\r)/gm, "").replace(/ {1,}/g,"");
        var oldPathDArr = SVGTransformer.getArrayOfPathDComponents(oldPathDStr);
        if (oldPathDStr[1] == ' ')oldPathDArr = oldPathDStr.split(' '); 
        var newPathDArr: any[] = [];
        //console.log('in',oldPathDStr);
        var commandParams, absOrRel, oldPathDComp, newPathDComp;
    
        // element index
        var idx = 0;
        //log(oldPathDArr);
        while (idx < oldPathDArr.length) {
    
            var oldPathDComp = oldPathDArr[idx];
            if (/^[A-Za-z]$/.test(oldPathDComp)) { // component is a single letter, i.e. an svg path command
                newPathDArr[idx] = oldPathDArr[idx];
                switch (oldPathDComp.toUpperCase()) {
                    case "A": // elliptical arc command...the most complicated one
                        commandParams = ELLIPTICAL_ARC;
                        break;
                    case "H": // horizontal line; requires only an x-coordinate
                        commandParams = JUST_X;
                        break;
                    case "V": // vertical line; requires only a y-coordinate
                        commandParams = JUST_Y;
                        break;
                    case "Z": // close the path
                        commandParams = NONE;
                        break;
                    default: // all other commands; all of them require both x and y coordinates
                        commandParams = BOTH_X_AND_Y;
                }
                absOrRel = ((oldPathDComp === oldPathDComp.toUpperCase()) ? ABSOLUTE : RELATIVE);
                // lowercase commands are relative, uppercase are absolute
                idx += 1;
            } else { // if the component is not a letter, then it is a numeric value
                var baseX, baseY,moveX, moveY;
                if (absOrRel === ABSOLUTE) { // the translation is required for absolute commands...
                    baseX = pathBaseX; 
                    baseY = pathBaseY;
                    moveX = pathTranslX;
                    moveY = pathTranslY;
                } else if (absOrRel === RELATIVE) { // ...but not relative ones
                    baseX = 0;
                    baseY = 0;
                    moveX = 0;
                    moveY = 0;
                }
                switch (commandParams) {
                    // figure out which of the numeric values following an svg command
                    // are required, and then transform the numeric value(s) from the
                    // original path d-attribute and place it in the same location in the
                    // array that will eventually become the d-attribute for the new path
                    case BOTH_X_AND_Y:
                        newPathDArr[idx] = Number(oldPathDArr[idx] - baseX) * scaleX + baseX +moveX;
                        newPathDArr[idx + 1] = Number(oldPathDArr[idx + 1] - baseY) * scaleY + baseY + moveY;
                        idx += 2;
                        break;
                    case JUST_X:
                        newPathDArr[idx] = Number(oldPathDArr[idx] - baseX) * scaleX + baseX + moveX;
                        idx += 1;
                        break;
                    case JUST_Y:
                        newPathDArr[idx] = Number(oldPathDArr[idx] - baseY) * scaleY + baseY + moveY;
                        idx += 1;
                        break;
                    case ELLIPTICAL_ARC:
                        // the elliptical arc has x and y values in the first and second as well as
                        // the 6th and 7th positions following the command; the intervening values
                        // are not affected by the transformation and so can simply be copied
                        newPathDArr[idx] = Number(oldPathDArr[idx] - baseX) * scaleX + baseX + moveX;
                        newPathDArr[idx + 1] = Number(oldPathDArr[idx + 1] - baseY) * scaleY + baseY + moveY;
                        newPathDArr[idx + 2] = Number(oldPathDArr[idx + 2]);
                        newPathDArr[idx + 3] = Number(oldPathDArr[idx + 3]);
                        newPathDArr[idx + 4] = Number(oldPathDArr[idx + 4]);
                        newPathDArr[idx + 5] = Number(oldPathDArr[idx + 5] - baseX) * scaleX + baseX + moveX;
                        newPathDArr[idx + 6] = Number(oldPathDArr[idx + 6] - baseY) * scaleY + baseY + moveY;
                        idx += 7;
                        break;
                    case NONE:
                        throw new Error('numeric value should not follow the SVG Z/z command');
                        break;
                }
            }
        }
    
        return newPathDArr.join(" ");
    }
    
    static getArrayOfPathDComponents(str) {
        // assuming the string from the d-attribute of the path has all components
        // separated by a single space, then create an array of components by
        // simply splitting the string at those spaces
        str = SVGTransformer.standardizePathDStrFormat(str);
        return str.split(" ");
    }
    
    static standardizePathDStrFormat(str) {
        // The SVG standard is flexible with respect to how path d-strings are
        // formatted but this makes parsing them more difficult. This function ensures
        // that all SVG path d-string components (i.e. both commands and values) are
        // separated by a single space.
        return str
            .replace(/,/g, " ")  // replace each comma with a space
            .replace(/-/g, " -")  // precede each minus sign with a space
            .replace(/([A-Za-z])/g, " $1 ")  // sandwich each   letter between 2 spaces
            .replace(/  /g, " ")  // collapse repeated spaces to a single space
            .replace(/ ([Ee]) /g, "$1")  // remove flanking spaces around exponent symbols
            .replace(/^ /g, "")  // trim any leading space
            .replace(/ $/g, ""); // trim any tailing space
    }

    static transformPolyline(oldPoints, bx,by,tx,ty,sx,sy){
        oldPoints = oldPoints.trim();
        let points = oldPoints.split(" ");
        let newPoints: string[] = [];
        for(let i = 0 ; i < points.length; i++){
            let coords: string[] = points[i].split(',');
    
            let coordX:number = (parseFloat(coords[0])-bx)*sx + bx + tx;
            let coordY:number = (parseFloat(coords[1])-by)*sy + by + ty;
            newPoints.push(coordX.toString()+','+coordY.toString());
        }
        return newPoints.join(' ');
    
    }
    
}
