
export class Common{
    // static log = (...args) => {
    //     if (DEBUG) {
    //         console.log.apply(console, args);
    //     }
    // }
    
    static getNode = (n, v) => {
        n = document.createElementNS("http://www.w3.org/2000/svg", n);
        for (var p in v)
            n.setAttributeNS(null, p.replace(/[A-Z]/g, function (m, p, o, s) { return "-" + m.toLowerCase(); }), v[p]);
        return n
    }  
    
    public static TOLERANCE = 1E-9;
    public static longEnough( u,  v,  w) {
        let  l = Math.sqrt(u*u + v*v + w*w);
        if (l > Common.TOLERANCE) {
            return l;
        } else {
            return -1;
        }
    }

    static  rotatePoint(x,y,z, a,b,c,u,v,w,theta){
        theta = theta;
        let l = Common.longEnough(u, v, w)
        u = u/l;  
        v = v/l;
        w = w/l;
        let u2 = u*u;
        let v2 = v*v;
        let w2 = w*w;
        let cosT = Math.cos(theta);
        let oneMinusCosT = 1 - cosT;
        let sinT = Math.sin(theta);

        return [(a*(v2 +w2) - u*(b*v + c*w - u*x -v*y -w*z))* oneMinusCosT + x*cosT + (-c*v + b*w - w*y + v*z)*sinT,
                (b*(u2 +w2) - v*(a*u + c*w - u*x -v*y -w*z))* oneMinusCosT + y*cosT + (c*u - a*w + w*x - u*z)*sinT,
                (c*(u2 +v2) - w*(a*u + b*v - u*x -v*y -w*z))* oneMinusCosT + z*cosT + (-b*u + a*v - v*x + u*y)*sinT
        ]
    }

    static bboxFromArray(arr: Point[]){
        let minX = 1000000;
        let maxX = -1000000;

        let minY = 1000000;
        let maxY = -1000000;
        
        
        for (let i = 0; i < arr.length; i++) {
            const e = arr[i];
            if (e.x < minX){
                minX = e.x;
            }
            if (e.y < minY){
                minY = e.y;
            }

            if (e.x > maxX){
                maxX = e.x;
            }
            if (e.y > maxY){
                maxY = e.y;
            }

        }
        return [minX, minY, maxX, maxY];

    }
    
}

export class ContentItem {
    // parameters: doc - plain JS object, proto - class we want to cast to (subclass of ContentItem)
    static castAs<T extends ContentItem>(doc: T, proto: typeof ContentItem): T {
        // if we already have the correct class skip the cast
        if (doc instanceof proto) { return doc; }
        // create a new object (create), and copy over all properties (assign)
        const d: T = Object.create(proto.prototype);
        Object.assign(d, doc);
        // reason to extend the base class - we want to be able to call init() after cast
        d.init(); 
        return d;
    }
    // another method casts an array
    static castAllAs<T extends ContentItem>(docs: T[], proto: typeof ContentItem): T[] {
        return docs.map(d => ContentItem.castAs(d, proto));
    }
    init() { }
} 


export interface IPoint{
    x: number;
    y: number;
    z :number;
}

export interface ILine {
    p1: IPoint;
    p2: IPoint;
}

export interface IFold {
    vector: IPoint;
    point: IPoint;
    angle: number;
}

export interface ISeam {
    //line: Line;
    point: IPoint;
    key: string;
    dash: string;
}


export class Point {
    x: number;
    y: number;
    z :number = 0;
    constructor(x: number, y: number, z: number=0) {
        this.x = +x;
        this.y = +y;
        this.z = z;
    }
}

export class Line {
    p1: Point;
    p2: Point;
}

export class Fold {
    vector: Point;
    point: Point;
    angle: number;
}

export class Seam {
    //line: Line;
    point: Point;
    key: string;
    dash: string;
}
