import { SVGCanvas } from "../../src/canvas/SVGCanvas";
import { ApiProvider } from "../../src/providers/ApiProvider";
import { Globals } from "../../src/Globals";
import { DAEParser } from "./../../src/collada/dae";
import { Primitive } from "./../../src/paper/Primitive";
import { PaperFace } from "./../../src/paper/PaperFace";
import { Viewer } from "./../../src/paper/Viewer";
import { PaperModel, IPaperModel } from "../../src/paper/PaperModel";
import { ContentItem } from "../../src/paper/Common";


//declare var $;
declare var jsPDF;
declare var svg2pdf;
declare var CanvasFloodFiller;

export class Korobka{
    containersvg;
    svgtemp;
    textElement;
    textWarningElement;
    unwrapWidth;
    unwrapHeight;

    limWidth = 1500;
    limHeight = 1500;
    modelName;
    modelW;
    modelH;
    modelL;
    maskPng;
    dae;
    svgCanvas:SVGCanvas;
    modeljson: any;
    model : PaperModel;
    viewer:Viewer;
    //fileName = null;
    pdfName = null;
    targets = [];
    imgWidth = 0;
    imgHeight = 0;
    nomerZakaza = '';

    params:any = {};
    initStage(){
        
        this.svgCanvas.resize(4000,3000);
    }

    constructor (){
        this.containersvg = document.getElementById('containersvg');
        this.svgtemp = document.getElementById('svgtemp');
        this.textElement = document.getElementById("text1");
        this.textWarningElement = document.getElementById("text2");

        /* this.modelW = document.getElementById("modelW")
        this.modelH = document.getElementById("modelH")
        this.modelL = document.getElementById("modelL")
        if (this.modelW == null) this.modelW = document.getElementById("modelW-0")
        if (this.modelH == null) this.modelH = document.getElementById("modelH-0")
        if (this.modelL == null) this.modelL = document.getElementById("modelL-0") */
    
        this.svgCanvas = new SVGCanvas();
        this.svgCanvas.init(this.containersvg);
    
        this.initStage();
    
        this.svgtemp.setAttribute("style", "zoom:0.2");
        this.dae = new DAEParser();
        this.viewer = new Viewer();
    }

    setConfig(conf){
        Globals.config = conf;
    }

    calcSize(){
        //const svgElement = this.svgCanvas.svg;
        //const width = this.svgCanvas.width, height =  this.svgCanvas.height;
        if (this.unwrapWidth > this.unwrapHeight){
        
        }else{
            let tmp = this.unwrapWidth;
            this.unwrapWidth = this.unwrapHeight;
            this.unwrapHeight=  tmp;
        }
        this.textElement = document.getElementById("text1");
        if (this.textElement) this.textElement.innerHTML = "Размер развертки: "+ this.unwrapWidth+" мм Х "+this.unwrapHeight + " мм";
        let warning = [];
       
        if (this.unwrapWidth > this.limWidth){
            warning = ["ширина листа больше допустимой на "+(this.unwrapWidth-this.limWidth)+" мм"];
        }
        if (this.unwrapHeight > this.limHeight){
            warning.push(" высота листа больше допустимой на "+(this.unwrapHeight-this.limHeight)+" мм");
        }
        if (warning.length>0){
            this.textWarningElement.innerHTML = warning.join(', ');
            this.textWarningElement.style.display = "block";
           
        }else{
            this.textWarningElement.style.display = "none";
           
        }
    
    }

    async loadModel(modelName){
        this.modelName = modelName;
        this.initStage();

        let model = await ApiProvider.getModel(modelName);
        this.modeljson = JSON.stringify(model);
     //   this.model = new PaperModel();
        this.model = ContentItem.castAs<PaperModel>(model ,PaperModel);
    //    this.model = new PaperModel();
    //    let res = await this.model.read(modelName);//.then(onSelectHandler);

        // this.modelW.value = this.model.w;
        // this.modelH.value = this.model.h;
        // this.modelL.value = this.model.l;

        this.svgCanvas.clear();
        //let data = await this.calcModel(modelName);
        let data = await this.loadHandler(this.model.w,this.model.h,this.model.l);
        this.calcSize();
        this.viewer.loadgl(this.dae.daeTemplate("",data.saved,data.saved_mask), {campos_x:this.model.campos_x, campos_y:this.model.campos_y, campos_z:this.model.campos_z, lookpos_x:this.model.lookpos_x, lookpos_y:this.model.lookpos_y, lookpos_z:this.model.lookpos_z});
        return data;
        //this.genPDF();
    }

    async recalcModel(w,h,l){
       this.svgCanvas.clear();
    //   let res = await this.model.read(this.modelName);//.then(loadHandler);
        this.model = ContentItem.castAs<PaperModel>(JSON.parse(this.modeljson),PaperModel);
       let data = await this.loadHandler(w,h,l);
       this.calcSize();
       this.viewer.loadgl(this.dae.daeTemplate("",data.saved,data.saved_mask), {campos_x:this.model.campos_x, campos_y:this.model.campos_y, campos_z:this.model.campos_z, lookpos_x:this.model.lookpos_x, lookpos_y:this.model.lookpos_y, lookpos_z:this.model.lookpos_z});
       return data;
    }

    async loadHandler(w,h,l){
        //this.model.resize({ w: this.modelW.value, h: this.modelH.value, l: this.modelL.value });
        this.model.resize({ w: w, h: h, l: l });
        let targetX, targetY, targetW, targetH;
        let sx = 1;
        let sy = 1;
        var MMtoPixKoef = 1;
        for (let i = 0; i < this.model.faces.length; i++) {
            console.log('scale', i, this.model.faces[i].basis);
            this.model.faces[i].initGeomerty();
          
            if (this.model.faces[i].specification['w'][0] > 0) {
                sx = this.model.faces[i].specification['w'][0] * this.model.kw;
                //log(this.model.faces[i].bbox.width, sx * this.model.faces[i].bbox.width);
                this.model.faces[i].scaleGeometry(sx, 1);
                MMtoPixKoef = this.model.w/this.model.faces[i].bbox.width;
    
            }
            if (this.model.faces[i].specification['w'][1] > 0) {
                sy = this.model.faces[i].specification['w'][1] * this.model.kw;
                //log(this.model.faces[i].bbox.height, sy * this.model.faces[i].bbox.height);
                this.model.faces[i].scaleGeometry(1, sy);
            }
            if (this.model.faces[i].specification['h'][0] > 0) {
                sx = this.model.faces[i].specification['h'][0] * this.model.kh;
                // log(this.model.faces[i].bbox.width, sx * this.model.faces[i].bbox.width);
                this.model.faces[i].scaleGeometry(sx, 1);
    
            }
            if (this.model.faces[i].specification['h'][1] > 0) {
                sy = this.model.faces[i].specification['h'][1] * this.model.kh;
                // log(this.model.faces[i].bbox.height, sy * this.model.faces[i].bbox.height);
                this.model.faces[i].scaleGeometry(1, sy);
            }
    
            if (this.model.faces[i].specification['l'][0] > 0) {
                sx = this.model.faces[i].specification['l'][0] * this.model.kl;
                //log(this.model.faces[i].bbox.width, sx * this.model.faces[i].bbox.width);
                this.model.faces[i].scaleGeometry(sx, 1);
    
            }
            if (this.model.faces[i].specification['l'][1] > 0) {
                sy = this.model.faces[i].specification['l'][1] * this.model.kl;
                //log(this.model.faces[i].bbox.height, sy * this.model.faces[i].bbox.height);
                this.model.faces[i].scaleGeometry(1, sy);
            }
    
    
        }
        console.log(this.model.faces);
    
        for (let j = 0; j < this.model.faces.length; j++) {
            this.snapPrimitives(this.model.faces[j]);
        }
    
        let faces = this.model.faces.slice();
        let acc: PaperFace[] = [];
        this.snapFaces(acc, faces, faces[0]);
    
        let minX = 100000;
        let minY = 100000;
        let maxX = -100000;
        let maxY = -100000;
        for (let i = 0; i < this.model.faces.length; i++) {
    
            this.model.faces[i].calcBBox(this.svgCanvas.svg);
            if (this.model.faces[i].bbox.x < minX) {
                minX = this.model.faces[i].bbox.x;
            }
            if (this.model.faces[i].bbox.y < minY) {
                minY = this.model.faces[i].bbox.y;
            }
            if (this.model.faces[i].bbox.x + this.model.faces[i].bbox.width > maxX) {
                maxX = this.model.faces[i].bbox.x + this.model.faces[i].bbox.width;
            }
            if (this.model.faces[i].bbox.y + this.model.faces[i].bbox.height > maxY) {
                maxY = this.model.faces[i].bbox.y + this.model.faces[i].bbox.height;
            }
        }
    
        //this.unwrapWidth = Math.floor( (maxX-minX)*MMtoPixKoef)+1;
        //this.unwrapHeight = Math.floor( (maxY-minY)*MMtoPixKoef)+1;
        this.unwrapWidth = Math.floor( (maxX-minX)*MMtoPixKoef + 0.5);
        this.unwrapHeight = Math.floor( (maxY-minY)*MMtoPixKoef + 0.5);
    
        this.model.translate(-minX, -minY);
    
    
    
        targetX = this.model.faces[0].bbox.x;
        targetY = this.model.faces[0].bbox.y;
        targetW = this.model.faces[0].bbox.width;
        targetH = this.model.faces[0].bbox.height;
        this.targets = [];
        for (let i = 0; i < this.model.faces.length; i++) {
            this.targets.push({
                x: this.model.faces[i].bbox.x, y: this.model.faces[i].bbox.y,
                w: this.model.faces[i].bbox.width, h: this.model.faces[i].bbox.height
            });
        }
    
    
        console.log('bbox', targetX, targetY, targetW, targetH);
        this.model.scale3d(1 / 100);
    
    
        // for (let i = 0; i < this.model.faces.length; i++) {
    
        //     //this.model.faces[i].calcBBox(svg);
    
        // }
        this.model.width = (maxX - minX) / 100;
        this.model.height = (maxY - minY) / 100;
    
        for (let i = 0; i < this.model.faces.length; i++) {
            //    this.model.faces[i].translate(-minX, -minY);
            let geom = this.model.faces[i].drawGeometry(this.svgCanvas.svg);
            this.svgCanvas.addGeomerty(geom);
        }
    
        this.model.prepare3dFaces();
    
        let maimFace = this.model.faces.filter(x => { return x.fold == null });
        this.foldFacesGlobal(this.model.faces, maimFace[0]);
    
        let [minx, miny, maxx, maxy] = this.model.getBbox3d();
        console.log(minx, miny, maxx, maxy);
        this.model.translate3d(-(minx + maxx) / 2, -(miny + maxy) / 2, 0);
    
        for (let i = 0; i < this.model.faces.length; i++) {
            this.model.faces[i].genBackside(i + '_b');
        }
    
        //dae.read('planes.dae').then(daeHandler);
        
        let bb=  this.svgCanvas.svg.getBBox();
        this.svgCanvas.resize(bb.width+1, bb.height+1);
        var xml = new XMLSerializer().serializeToString(this.svgCanvas.svg);
        // make it base64
        var svg64 = btoa(xml);
        var b64Start = 'data:image/svg+xml;base64,';
    
        // prepend a "header"
        var image64 = b64Start + svg64;
        var canvas: any = document.getElementById('canvas');
    
        //var img = document.querySelector('img');
        var img: any = document.getElementById('imgtemp');
        // set it as the source of the img element

        //img.src = image64;
        await this.loadImage(img, image64);
        //img.onload = function () {
            // draw the image onto the canvas
            canvas.width = img.width + 20;
            canvas.height = img.height + 20;
            let ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          
    
    
    
            ctx.drawImage(img, 10, 10);
    
            //ctx.fillStyle = "rgba(255,0,0,1.0)";
            //ctx.fillFlood(5, 5, 0);
            //floodFill(ctx, 5, 5, 0xFF0000FF);
            //floodFill(ctx, 5, 5 , [255, 0, 0, 255],128);
            var cff = new CanvasFloodFiller();
            var _background = {r: 255, g: 0, b: 0, a: 255};
            //cff.floodFill(ctx, 5, 5, _background);
    
            for (let i = 0; i < this.model.faces.length; i++) {
                if (this.model.faces[i].fillpoint) {
                    for (let j = 0; j < this.model.faces[i].fillpoint.length; j++) {
                        let el = this.model.faces[i].fillpoint[j];
                        console.log( Math.round( el.x+5), Math.round(el.y+5));
                        //ctx.fillFlood(el.x+5, el.y+5, 5);
                        //floodFill(ctx,el.x+5, el.y+5,0xFF0000FF);
                        cff.floodFill(ctx, Math.round( el.x+5), Math.round(el.y+5), _background);
                    }
                }
    
            }
            let el = this.model.faces[1].fillpoint[0];
    
            //floodFill(ctx, el.x + 5, el.y + 5, 0xFF0000FF);
            cff.floodFill(ctx, 5, 5, _background);
    
            // var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            let ncols = canvas.width;
            let nrows = canvas.height;
            var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            var rgba = imgData.data;
    
    
            for (var r = 0; r < nrows; ++r) {
                for (var c = 0; c < ncols; ++c) {
                    if (!(rgba[r * 4 * ncols + 4 * c + 0] == 255 && rgba[r * 4 * ncols + 4 * c + 1] == 0 && rgba[r * 4 * ncols + 4 * c + 2] == 0 && rgba[r * 4 * ncols + 4 * c + 3] == 255)) {
                        rgba[r * 4 * ncols + 4 * c + 0] = 255;
                        rgba[r * 4 * ncols + 4 * c + 1] = 255;
                        rgba[r * 4 * ncols + 4 * c + 2] = 255;
                        rgba[r * 4 * ncols + 4 * c + 3] = 255;
                    } else {
                        rgba[r * 4 * ncols + 4 * c + 0] = 0;
                        rgba[r * 4 * ncols + 4 * c + 1] = 0;
                        rgba[r * 4 * ncols + 4 * c + 2] = 0;
                        rgba[r * 4 * ncols + 4 * c + 3] = 0;
                    }
                }
            }
    
            this.imgWidth = (maxX - minX) + 1;
            this.imgHeight = (maxY - minY) + 1;
            canvas.width = this.imgWidth;
            canvas.height = this.imgHeight;
    
    
            
           this.svgCanvas.resize(this.imgWidth, this.imgHeight);
    
    
            ctx.putImageData(imgData, -10, -10);
    
    
            let dataURL = canvas.toDataURL("image/png");
    
            let flag: any = document.getElementById("chb");
    
            //this.fileName = 
            let data = await ApiProvider.savePng(dataURL, '1', this.model.inittexture_filename,this.nomerZakaza, this.targets, flag? flag.checked:true)
            .then(response => response.json())
            .then(json => {
                return json;
            })
            .catch(e => {
                return e
            });
             //   .then(r => r.json().then(
             //       (data) => {
                    console.log(data);
                    this.daeHandler(data);
                    //   img.src = "saved/1_masked.png";
                    let razv: any = document.getElementById('razvertka');
                    //razv.src = Globals.config.KOROBKI_SAVED_PATH+this.nomerZakaza+"/" + "1_masked.png";
                    razv.src = Globals.config.KOROBKI_UPLOAD_PATH + data.saved_mask;
              //  }));
            
        
          
            this.maskPng = dataURL;
            
    
        //}
        
        return data;
        
    };

    loadImage(img, src){
        return new Promise(resolve => {
            img.onload = () => resolve(img);
            img.src = src;
        });
    }

    


    foldFacesGlobal (faces, face)  {
        let acc: PaperFace[] = [];
        for (let i = 0; i < face.seams.length; i++) {

            const element = face.seams[i];
            acc = [];
            this.foldFaces(acc, faces, face, face.seams[i].key);
            console.log(acc);

            acc.forEach((x: PaperFace) => {
                if (acc[0].fold) {
                    x.rotate3d(acc[0].fold);
                }

            });
            if (acc.length > 0) {
                this.foldFacesGlobal(acc.slice(), acc[0]);
            }

        }
    }

    foldFaces(acc, faces, face, seamKey = -1) {

        faces = faces.filter(x => x != face);
        if (seamKey !== -1) {
            let [faceInd, seamInd] = this.faceHasSeamByKey(faces, seamKey);

            if (faceInd >= 0) {        //
                acc.push(faces[faceInd]);
                this.foldFaces(acc, faces, faces[faceInd]);

            }
        } else {
            for (let i = 0; i < face.seams.length; i++) {

                let [faceInd, seamInd] = this.faceHasSeamByKey(faces, face.seams[i].key);

                if (faceInd >= 0) {        //
                    acc.push(faces[faceInd]);
                    this.foldFaces(acc, faces, faces[faceInd]);

                }
            }
        }
        if (acc.length > 0) {

        }

    }



    snapFaces(acc, faces, face) {
        acc.push(face);
        faces = faces.filter(x => x != face);
        for (let i = 0; i < face.seams.length; i++) {
    
            let [faceInd, seamInd] = this.faceHasSeamByKey(faces, face.seams[i].key);
            //log(face.seams[i].key, ind);
            if (faceInd >= 0) {        //
                //log('connect key', face.seams[i].key);
                let dx = face.seams[i].point.x - faces[faceInd].seams[seamInd].point.x;
                let dy = face.seams[i].point.y - faces[faceInd].seams[seamInd].point.y;
                //faces[faceInd].matrix = faces[faceInd].matrix.translate(dx, dy);
                faces[faceInd].translate(dx, dy);
    
                this.snapFaces(acc, faces, faces[faceInd]);
                //break;
            }
        }
    
    }

    faceHasSeamByKey(faces, key)  {
        let seamIndex;
        for (let j = 0; j < faces.length; j++) {
            seamIndex = faces[j].seams.findIndex(x => x.key == key);
            if (seamIndex >= 0) return [j, seamIndex];
        }
        return [-1, -1];
    }

    snapPrimitives(face){
        //snap
        let ps = face.primitives;
        for (let j = 0; j < ps.length; j++) {
            let firstPrim = ps[j];
            if (firstPrim.isConst) {
                for (let i = 0; i < firstPrim.seams.length; i++) {
                    for (let p = 0; p < ps.length; p++) {
                        let secondPrim = ps[p];
                        if (secondPrim != firstPrim) {
                            let sInd = this.primitiveHasSeamByKey(secondPrim, firstPrim.seams[i].key);
                            if (sInd >= 0 && firstPrim.seams[i].key[0] != 'l') {        //
                                //log('snap primitive key', firstPrim.seams[i].key);
                                let dx = secondPrim.seams[sInd].point.x - firstPrim.seams[i].point.x;
                                let dy = secondPrim.seams[sInd].point.y - firstPrim.seams[i].point.y;
                                firstPrim.translateGeometry(dx, dy);
    
                            }
                        }
                    }
                }
            }
        }
    
        //connect
        let used = [];
        for (let j = 0; j < ps.length; j++) {
            let firstPrim = ps[j];
            if (firstPrim.isConst) {
                
                for (let i = 0; i < firstPrim.seams.length; i++) {
                    for (let p = 0; p < ps.length; p++) {
                        let secondPrim = ps[p];
                        if (secondPrim != firstPrim) {
                            let sInd = this.primitiveHasSeamByKey(secondPrim, firstPrim.seams[i].key);
                            
                            if (sInd >= 0 && firstPrim.seams[i].key[0] == 'l' && used.indexOf(firstPrim.seams[i].key)==-1) {        //
                                used.push(firstPrim.seams[i].key);
                                //log('connect primitive key', firstPrim.seams[i].key);
                                let dx = secondPrim.seams[sInd].point.x - firstPrim.seams[i].point.x;
                                let dy = secondPrim.seams[sInd].point.y - firstPrim.seams[i].point.y;
                                let p = new Primitive();
                                p.geometrySVG = '<line x1="' + firstPrim.seams[i].point.x + '" y1="' + firstPrim.seams[i].point.y + '" ' +
                                    'x2="' + secondPrim.seams[sInd].point.x + '" y2="' + secondPrim.seams[sInd].point.y + '"  stroke="#009640" ></line>';
                                face.primitives.push(p);
    
                                //face.translateGeometry(firstPrim, dx,dy);
    
                            }
                        }
                    }
                }
            }
        }
    
    }
    
    primitiveHasSeamByKey(prim, key){
        let seamIndex;
        seamIndex = prim.seams.findIndex(x => x.key == key);
        if (seamIndex >= 0) return seamIndex;
        return -1;
    }

    daeHandler(data){
        //console.log(data);
        this.dae.nodes = [];
        this.dae.geometry = [];
    
        for (let i = 0; i < this.model.faces.length; i++) {
            const element = this.model.faces[i];
            this.dae.addGeometry(element, i);
        }
        //console.log(dae.daeTemplate());
        //console.log(dae.nodes.join(""));

        this.params.size = {w:this.model.w, h:this.model.h, l:this.model.l}
        this.params.dae = this.dae.daeTemplate("",data.saved,data.saved_mask);
        this.params.mask = this.maskPng; 
        this.params.file1 = data.saved;
        this.params.file2 = data.saved_mask;
        this.params.dae_boxes = this.targets;
        this.params.dae_full = true;
        this.params.pdf =  this.pdfName;

        /*
        if (window.parent){
            let flag: any = window.document.getElementById("chb");
            //window.parent.postMessage({message:dae.daeTemplate("",data.saved,data.saved_mask), mask: maskPng, file1:data.saved, file2:data.saved_mask, dae_boxes: targets, dae_full:flag.checked}, 'http://192.168.0.7/');
            window.parent.postMessage({size: JSON.stringify({ w: this.modelW.value, h: this.modelH.value, l: this.modelL.value }) ,message:this.dae.daeTemplate("",data.saved,data.saved_mask), mask: this.maskPng, file1:data.saved, file2:data.saved_mask, dae_boxes: this.targets, dae_full:flag?flag.checked:true, pdf: this.pdfName}, Globals.config.host);
    
           
        
        }*/
    
        //loadgl(dae.daeTemplate("",data.saved,data.saved_mask));
    }


    async genPDF(){
        const svgElement = this.svgCanvas.svg;
        const width = this.svgCanvas.width, height =  this.svgCanvas.height;

        let orientation = 'l'; //Orientation of the first page. Possible values are "portrait" or "landscape" (or shortcuts "p" or "l").  https://artskydj.github.io/jsPDF/docs/jsPDF.html
        if (height > width){
            orientation = 'p';
        }
        // create a new jsPDF instance
        const pdf = new jsPDF(orientation, 'pt', [width, height]);

        // render the svg element
        svg2pdf(svgElement, pdf, {
            xOffset: 0,
            yOffset: 0,
            scale: 1
        });

        // get the data URI
        //const uri = pdf.output('datauristring');

        // or simply save the created pdf
        //pdf.save('myPDF1.pdf');
        var blob = pdf.output('blob');

        let obj = await ApiProvider.uploadImage(blob).then(r => r.json().then(data => ({ status: r.status, body: data })))
        //.then(obj => {

        let pdfName = obj.body.saved;
        console.log('pdf saved', pdfName);
        return pdfName;
        //});
    }
}