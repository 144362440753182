import { Globals } from "../Globals";

export class ApiProvider {

	static async savePng(png, name, fileName, nomerZakaza, boxes, full) {

		//  data.append('coords',  JSON.stringify({x:0, y:0, w:100, h:100}));
		//nomerZakaza = nomerZakaza ? nomerZakaza : 
		let data = { imgBase64: png, name: name, fname: fileName, nomerZakaza: nomerZakaza, boxes: boxes, full: full };
		// let headers = new Headers({'Content-Type': 'application/json; charset=utf-8'  });
		// let options = new RequestOptions({ headers: headers });
		// const response:Response = await this.http.post(Globals.API_URL+"savepng/",JSON.stringify(data), options).toPromise();
		// let result = response.json();
		// if (result && result.files){

		// 	console.log(result);
		// }
		// console.log(result);
		const response = await  fetch(Globals.config.KOROBKI_API_URL + "savepng/", {
			headers: { 'Content-Type': 'application/json; charset=utf-8' },
			method: 'POST',
			body: JSON.stringify(data)
		})
		return response;
		//return result;
	}

	static uploadImage(file) {
		//		let data = {imgBase64:png, name:name};
		var data = new FormData()

		//data.append('section', 'general');
		//data.append('action', 'previewImg');
		data.append('file', file);

		//data.append('user', 'hubot')

		return fetch(Globals.config.KOROBKI_API_URL + "upload/", {
			method: 'POST',		
			body: data
			// headers: {
			// 	"Content-Type": "multipart/form-data"
            //     //"Content-Type": "application/pdf",
            //     // "Content-Type": "application/x-www-form-urlencoded",
			//}
			
			
		})

		// return fetch(Globals.API_URL+"upload/", {
		//     headers :{ 'Content-Type': 'application/json; charset=utf-8' },
		//     method: 'POST',
		//     body: JSON.stringify(data)
		//   })

		//return result;
	}

	static getModels() {
		return fetch(Globals.config.KOROBKI_API_URL + "models/", {
			method: 'POST',
			body: null
		});
	}

	static async getModel(name) {
		let data = {name:name};
		let response = await fetch(Globals.config.KOROBKI_API_URL + "loadmodel/", {
			method: 'POST',
			headers :{ 'Content-Type': 'application/json; charset=utf-8' },
			body: JSON.stringify(data)
		})
		let ans = await response.json();
		return ans;
	}

	static getConfig() {
		return fetch(Globals.CONFIG_URL, {
			method: 'GET',
			body: null
		});
	}


}