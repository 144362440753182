import { PaperFace, IPaperFace } from "./PaperFace";
import { Globals } from "../Globals";
import { ContentItem } from "./Common";


export class IPaperModel {
    faces: PaperFace[];
    width: number;
    height: number;
    w:number;
    h:number;
    l:number;
    kw:number;
    kh:number;
    kl:number;
    inittexture_filename:string;
    curent_w:number;
    curent_h:number;
    curent_l:number;
    campos_x:number;
    campos_y:number;
    campos_z:number;
    lookpos_x:number;
    lookpos_y:number;
    lookpos_z:number;
    campos2_x:number;
    campos2_y:number;
    campos2_z:number;
    up2_x:number;
    up2_y:number;
    up2_z:number;
    lookpos2_x:number;
    lookpos2_y:number;
    lookpos2_z:number;
    resize;
    findPair;
    translate;
    translate3d;
    getBbox3d;
    scale3d;
    center;
    prepare3dFaces;
}

export class PaperModel extends ContentItem {
    faces: PaperFace[];
    width: number;
    height: number;
    w:number;
    h:number;
    l:number;
    inittexture_filename:string = '';
    curent_w:number;
    curent_h:number;
    curent_l:number;
    campos_x:number;
    campos_y:number;
    campos_z:number;
    lookpos_x:number = 0;
    lookpos_y:number = 0;
    lookpos_z:number = 0;
    campos2_x:number;
    campos2_y:number;
    campos2_z:number;
    up2_x:number;
    up2_y:number;
    up2_z:number;
    lookpos2_x:number = 0;
    lookpos2_y:number = 0;
    lookpos2_z:number = 0;
    kw:number = 1;
    kh:number = 1;
    kl:number = 1;
/*
    read = (file) => {
        let promise = new Promise((resolve, reject) => {
            fetch(Globals.config.KOROBKI_MODELS_PATH+file)
                .then(r => r.text())
                .then(str => (new window['DOMParser']()).parseFromString(str, "text/xml"))
                .then(_data => {
                    
                    let groups = _data.querySelectorAll('svg>g');
console.log(_data.querySelectorAll('svg>text'));
                    let specNode =_data.querySelectorAll('svg>text');
                    for (let i = 0 ; i < specNode.length; i++){
                        let el = specNode[i];//.childNodes[i];
                        if (el.nodeName == '#text') continue;
                        if (el.nodeName == 'text'){
                            console.log(el.innerHTML);
                            try{
                                let obj = JSON.parse(el.innerHTML);
                                this.w = obj.w;
                                this.h = obj.h;
                                this.l = obj.l;

                            }catch(e){
                                console.log('спецификация задана неверно');
                            }
                        };
                    }
                    this.faces = [];//this.faces || [];
                    for (let i = 0; i < groups.length; i++) {
                        let f = new PaperFace();
                        console.log('face',i);
                        
                        for (let j = 0; j<groups[i].childNodes.length; j++ ){
                            if (groups[i].childNodes[j].nodeName=='#text'){
                            
                            }else{
                                f.parsePrimitive(groups[i].childNodes[j]);
                            }
                            
                        }
 
                        this.faces.push(f);
                    }

                    
                    resolve(this);
                })


        });
        return promise;
    }
*/
/*
    
    extend(object){
        for (const key in object) {
            if (object[key] instanceof Array){
                let arr: any[] = object[key];
                switch (key) {
                    case "faces":
                        this.faces = arr.map(x=> new PaperFace(x) )
                        break;
                }

               
            }else{
                if (object.hasOwnProperty(key) ) {
                    this[key] = object[key];
                    
                }
            }
        }
    }*/

    constructor(){
        super();
    }



    init() {
        if (this.faces) {
            this.faces = ContentItem.castAllAs<PaperFace>(this.faces, PaperFace);
        }
    }

    resize(newSize){
        this.curent_w = newSize.w;
        this.curent_h = newSize.h;
        this.curent_l = newSize.l;
        this.kw = newSize.w / this.w;
        this.kh = newSize.h / this.h;
        this.kl = newSize.l / this.l;
    };

    findPair(face, key) {
        let pair = null;
        this.faces.forEach(f => {

            if (f !== face && f.seams.find(x => { return x.key == key })) {
                pair = face;
            };
        })
        return pair;
    }

    translate(dx,dy){
        for (let i = 0; i < this.faces.length; i++) {
            this.faces[i].translate(dx, dy);
           // model.faces[i].drawGeometry(svg);
        }

    }

    getBbox3d(){
        let minX = 1000000;
        let maxX = -1000000;

        let minY = 1000000;
        let maxY = -1000000;

        for (let i = 0; i < this.faces.length; i++) {
            let [tminx,tminy, tmaxx,tmaxy] = this.faces[i].bbox3d();
            if (tminx < minX){
                minX = tminx;
            }
            if (tminy < minY){
                minY = tminy;
            }

            if (tmaxx > maxX){
                maxX = tmaxx;
            }
            if (tmaxy > maxY){
                maxY = tmaxy;
            }

        }
        return [minX, minY, maxX, maxY];
    }

    scale3d(k){
        for (let i = 0; i < this.faces.length; i++) {
            this.faces[i].scale3d(k);
         
        }
    }

    translate3d(dx,dy,dz){
        for (let i = 0; i < this.faces.length; i++) {
            this.faces[i].translate3d(dx,dy,dz);
         
        }
    }


    center(){
        let minX = 100000;
        let minY = 100000;
        let maxX = -100000;
        let maxY = -100000;
        for (let i = 0; i < this.faces.length; i++) {
        
          
            if (this.faces[i].bbox.x < minX){
                minX = this.faces[i].bbox.x;
            }
            if (this.faces[i].bbox.y < minY){
                minY = this.faces[i].bbox.y;
            }
            if (this.faces[i].bbox.x > maxX){
                maxX = this.faces[i].bbox.x;
            }
            if (this.faces[i].bbox.y > maxY){
                maxY = this.faces[i].bbox.y;
            }
        }
        this.translate(-maxX/2,-maxY/2);

    }

    prepare3dFaces(){
        this.faces.forEach(f=>{
            f.prepare3Dface(this.width, this.height);
        });
    }


}
