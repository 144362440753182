export class ModelParser{
    static isPointDescriptor = (element)=>{
        let textCnt = 0;
        let circleCnt = 0;
        for (let i = 0 ; i < element.childNodes.length; i++){
            if (element.childNodes[i].nodeName == '#text') continue;
            if (element.childNodes[i].nodeName == 'text') textCnt++;
            if (element.childNodes[i].nodeName == 'circle') circleCnt++;
        }
        return textCnt ==1 && circleCnt ==1;
        //return (element.getElementsByTagName('circle').length == 1 && element.getElementsByTagName('text').length == 1);
    }

    static parsePointDescription(element){
        let text = '';
        let x:number = 0;
        let y:number = 0;
        for (let i = 0 ; i < element.childNodes.length; i++){
            
            if (element.childNodes[i].nodeName == 'text') text = element.childNodes[i].innerHTML;
            if (element.childNodes[i].nodeName == 'circle') {
                x = element.childNodes[i].getAttribute('cx');
                y = element.childNodes[i].getAttribute('cy');
            }
           
        }
        return {text: text, x:x,y:y}
    }

    static ifGraphicsDescriptor = (element)=>{
        return !ModelParser.isPointDescriptor(element);
    }
}

