
import { SVGTransformer } from "../SVGTransformer";
import { Primitive, IPrimitive } from "./Primitive";
import { ModelParser } from "./ModelParser";
import { Point, Fold, Seam, Common, IPoint, IFold, ISeam, ContentItem } from "./Common";


export interface IFace3d{
    p0 : IPoint;
    p1 : IPoint;
    p2 : IPoint;
    p3 : IPoint;

    normal: IPoint;
}

export class Face3d{
    p0 : Point;
    p1 : Point;
    p2 : Point;
    p3 : Point;

    normal: Point;
}

export interface IPaperFace {
    specification: any;
    basis: IPoint;
    fold: IFold;
    seams: ISeam[];
    fillpoint: IPoint[];
    //geometrySVG: string;
    bbox: any;
    face3d: IFace3d;
    face3dback: IFace3d;
    matrix: any;
    primitives: IPrimitive[];
    uvcoords: string;
    //transforms = [];
}

export class PaperFace extends ContentItem{
    specification: any;
    basis: Point;
    fold: Fold;
    seams: Seam[];
    fillpoint: Point[];
    //geometrySVG: string;
    bbox: any;
    face3d: Face3d;
    face3dback: Face3d;
    matrix: any;
    primitives: Primitive[];
    uvcoords = '';
    transforms = [];
    
    constructor(){
        super();
        this.primitives = [];
        this.fillpoint = [];
    }
/*
    extend(object){

    }*/

    init() {
        if (this.primitives) {
            this.primitives = ContentItem.castAllAs(this.primitives, Primitive);
        }
    }

    getGeometrySVG(){
        let s = '';
        for (let i = 0; i < this.primitives.length; i++) {
            const element = this.primitives[i];
            s += element.geometrySVG;
        }
        return s;
    }
    setGeometrySVG(svg){
        //this.
    }

    parsePrimitive(element){
        //for (let i = 0 ; i < element.childNodes.length; i++){
            //if (element.childNodes[i].nodeName == '#text') continue;
            //if (element.childNodes[i].nodeName == 'g'){
        if (element.nodeName == 'text'){ //face specification
            
            this.specification =  JSON.parse(element.innerHTML);
            //log('text spec ', this.specification);
        }   else // base or seam point
            if (ModelParser.isPointDescriptor(element)){
                // let el = element.childNodes[i];
            /*    let b = element.getElementsByTagName("circle")[0];
                let t = element.getElementsByTagName("text")[0];
                let desc = t.innerHTML;
                if (desc.length > 0){
                    let a = desc.split(",");
                    for (let i = 0 ; i < a.length; i++){
                    //    let seam =  new Seam();
                    //    seam.key = a[i];
                    //    seam.point = new Point(b.getAttribute('cx'), b.getAttribute('cy'));
                    //    this.seams.push(seam);
                        log('face seam', b.getAttribute('cx'), b.getAttribute('cy'));
                    }
                }else{
                    log('no point description', element.outerHTML);
                }*/
                this.parsePointDescription(element);
            
            }else
                if (element.nodeName == 'g'){ // graphics
                    //log('group graphics');
                    for (let i = 0 ; i < element.childNodes.length; i++){
                        let el = element.childNodes[i];
                        if (el.nodeName == '#text') continue;
                        if (el.nodeName == 'g'){
                            let p = new Primitive();
                            
                            for (let j = 0 ; j < el.childNodes.length; j++){
                                let el2 = el.childNodes[j];
                                if (el2.nodeName == '#text') continue;
                                if (ModelParser.isPointDescriptor(el2)){
                                    let desc = ModelParser.parsePointDescription(el2);
                                    let seam = new Seam();
                                    seam.key = desc.text;
                                    seam.point = new Point(desc.x, desc.y);
                                    p.seams.push(seam);
                                    //log('primitive seam',desc);
                                }else{
                               //     log(el2.outerHTML);        
                                    p.geometrySVG = el2.outerHTML;
                                }
                            }
                            this.primitives.push(p);
                        }else{
                            //log(el.outerHTML);
                            let p = new Primitive();
                            p.geometrySVG = el.outerHTML;
                            this.primitives.push(p);
                        }
                    }
                } 
            
        
    }

    parsePointDescription(element){
        this.seams = this.seams || [];
        
        let b = element.getElementsByTagName("circle")[0];
        let t = element.getElementsByTagName("text")[0];
        let l = element.getElementsByTagName("line")[0];

        let basis = new Point(b.getAttribute('cx'), b.getAttribute('cy'));

        if (l){
            //if (element.childNodes[i].nodeName == 'line') {
                let x1 = l.getAttribute('x1');
                let y1 = l.getAttribute('y1');
                let x2 = l.getAttribute('x2');
                let y2 = l.getAttribute('y2');
                //console.log('fold-',x1,y1,x2,y2);
            //}
            let f = new Fold();
            f.angle = 90;
            f.point = new Point(basis.x, basis.y, basis.z);
            f.vector = new Point(x2 - basis.x, y2 - basis.y, 0);
            f.angle = parseFloat(this.specification.a);
            this.fold = f;

        }
        let desc = t.innerHTML;
        if (desc.length > 0){
            let a = desc.split(",");
            for (let i = 0 ; i < a.length; i++){
                if (a[i] == 'b'){
                    
                    this.basis =  new Point(b.getAttribute('cx'), b.getAttribute('cy'));;
                }
                else
                    if (a[i] == 't'){
                        if (!this.fillpoint) this.fillpoint = [];
                        this.fillpoint.push( new Point(b.getAttribute('cx'), b.getAttribute('cy')));

                    }
                    else
                    {
                        let seam =  new Seam();
                        seam.key = a[i];
                        seam.point = new Point(b.getAttribute('cx'), b.getAttribute('cy'));
                        this.seams.push(seam);
                    }

            }
        }else{
            console.log('no point description');
        }
    }

    initGeomerty() {
        
        var svg = Common.getNode("svg", {});
        let g = Common.getNode("g", {});


        g.innerHTML = this.getGeometrySVG();
        svg.appendChild(g);
        document.body.appendChild(svg);

        let bb = g.getBBox();
        this.bbox = bb;

        var matrix = svg.createSVGMatrix();
        matrix = matrix.translate(0, 0);
        //matrix = matrix.translate(-bb.x, -bb.y);
        this.matrix = matrix;

        document.body.removeChild(svg);
        svg.setAttribute("width", bb.width);
        svg.setAttribute("height", bb.height);
    }

    translate(tx,ty){
        this.bbox.x +=tx;
        this.bbox.y +=ty;
        this.basis.x = (this.basis.x+tx);
        this.basis.y = (this.basis.y+ty);
        for (let i = 0; i < this.seams.length; i++) {
            this.seams[i].point.x = this.seams[i].point.x  + tx;
            this.seams[i].point.y = this.seams[i].point.y  + ty;
       
        }
        for (let i = 0; i < this.fillpoint.length; i++) {
            this.fillpoint[i].x = this.fillpoint[i].x  + tx;
            this.fillpoint[i].y = this.fillpoint[i].y  + ty;
       
        }

        if (this.fold){
            this.fold.point.x = this.fold.point.x  + tx;
            this.fold.point.y = this.fold.point.y  + ty;

        }

        for (let i = 0; i < this.primitives.length; i++) {
            const element = this.primitives[i];
            element.translateGeometry(tx,ty);
            
        }
    }

    
    scaleGeometry(sx, sy) {
        let tx = 0;
        let ty = 0;
        tx =  this.basis.x;
        ty =  this.basis.y;

        this.basis.x = (this.basis.x*sx);
        this.basis.y = (this.basis.y*sy);

       
        //scale seams
        for (let i = 0; i < this.seams.length; i++) {
            this.seams[i].point.x = (this.seams[i].point.x - tx)*sx + tx;
            this.seams[i].point.y = (this.seams[i].point.y - ty)*sy + ty;
       
        }

        for (let i = 0; i < this.fillpoint.length; i++) {
            this.fillpoint[i].x = (this.fillpoint[i].x - tx)*sx + tx;
            this.fillpoint[i].y = (this.fillpoint[i].y - ty)*sy + ty;
       
        }

        if (this.fold){
            this.fold.point.x = (this.fold.point.x - tx)*sx + tx;
            this.fold.point.y = (this.fold.point.y - ty)*sy + ty;

        }
        //scale geomerty
        let g = Common.getNode("g", {});
       // g.innerHTML = this.getGeometrySVG();
        let paths = g.getElementsByTagName('*');
        //let newHtml = '';
        //for (let i = 0; i < paths.length; i++) {
        for (let i = 0; i < this.primitives.length; i++) {
            let newHtml = '';
            g.innerHTML = this.primitives[i].geometrySVG;
            let element = g.childNodes[0]; //paths[i];
            if (element.tagName.toLowerCase() == 'path') {

                let stroke = element.getAttribute("stroke");

                let d = element.getAttribute("d");
                //console.log(d);
                let newd = SVGTransformer.getTransformedPathDStr(d.trim(), tx, ty,0,0, sx, sy);
                
                //newd = getTransformedPathDStr(newd, 0,0,sx,sy);
                if (stroke == '#009640'){
                        this.primitives[i].isConst = true;
                }
                let newPath = Common.getNode("path", {});
                for (var j = 0, attrs = element.attributes, l = attrs.length; j < l; j++) {
                    if (attrs.item(j).nodeName !== 'd' || stroke == '#009640') {
                        newPath.setAttribute(attrs.item(j).nodeName, attrs.item(j).nodeValue);
                    } else {
                        newPath.setAttribute("d", newd);
                    }
                }
                newPath.setAttribute("stroke-width", "0.1");
                newHtml += newPath.outerHTML;
            }
            if (element.tagName.toLowerCase() == 'polyline') {
                let points = element.getAttribute("points");
                let newp = SVGTransformer.transformPolyline(points, tx, ty, 0,0,sx, sy);
                let stroke = element.getAttribute("stroke");
                let newPath = Common.getNode("polyline", {});
                for (var j = 0, attrs = element.attributes, l = attrs.length; j < l; j++) {
                    if (attrs.item(j).nodeName !== 'points' || stroke == '#009640') {
                        newPath.setAttribute(attrs.item(j).nodeName, attrs.item(j).nodeValue);
                    } else {
                        newPath.setAttribute("points", newp);
                    }
                }
                newPath.setAttribute("stroke-width", "0.1");
                newHtml += newPath.outerHTML;
            }

            if (element.tagName.toLowerCase() == 'rect') {
                //log('rect', element);

                let x = parseFloat(element.getAttribute("x"));
                let y = parseFloat(element.getAttribute("y"));
                let width = element.getAttribute("width");
                let height = element.getAttribute("height");
                let newRect = Common.getNode("rect", {});
                for (var j = 0, attrs = element.attributes, l = attrs.length; j < l; j++) {
                    if (attrs.item(j).nodeName == 'x') {
                        newRect.setAttribute("x", (x - tx) * sx + tx);
                    } else if (attrs.item(j).nodeName == 'y') {
                        newRect.setAttribute("y", (y - ty) * sy + ty);
                    } else if (attrs.item(j).nodeName == 'width') {
                        newRect.setAttribute("width", width * sx);
                    } else if (attrs.item(j).nodeName == 'height') {
                        newRect.setAttribute("height", height * sy);
                    }
                    else {
                        newRect.setAttribute(attrs.item(j).nodeName, attrs.item(j).nodeValue);
                    }
                }
                newRect.setAttribute("stroke-width", "0.1");
                newHtml += newRect.outerHTML;

            }

            if (element.tagName.toLowerCase() == 'line') {
                //log('rect', element);

                let x1 = parseFloat( element.getAttribute("x1"));
                let y1 = parseFloat(element.getAttribute("y1"));
                let x2 = parseFloat(element.getAttribute("x2"));
                let y2 = parseFloat(element.getAttribute("y2"));
                let newLine = Common.getNode("line", {});
                for (var j = 0, attrs = element.attributes, l = attrs.length; j < l; j++) {
                    if (attrs.item(j).nodeName == 'x1') {
                        newLine.setAttribute("x1", (x1-tx) * sx + tx);
                    } else if (attrs.item(j).nodeName == 'y1') {
                        newLine.setAttribute("y1", (y1-ty) * sy + ty);
                    } else if (attrs.item(j).nodeName == 'x2') {
                        newLine.setAttribute("x2", (x2 - tx) * sx + tx);
                    } else if (attrs.item(j).nodeName == 'y2') {
                        newLine.setAttribute("y2", (y2 - ty) * sy + ty);
                    }
                    else {
                        newLine.setAttribute(attrs.item(j).nodeName, attrs.item(j).nodeValue);
                    }
                }
                newLine.setAttribute("stroke","#ee0000");
                newLine.setAttribute("stroke-width", "0.1");
                newHtml += newLine.outerHTML;

            }

            if (!this.primitives[i].isConst){
                for (let j = 0; j < this.primitives[i].seams.length; j++) {
                    const s = this.primitives[i].seams[j];
                    s.point.x = (s.point.x - tx)*sx + tx;
                    s.point.y = (s.point.y - ty)*sy + ty;
                    
                }
            }

            this.primitives[i].geometrySVG = newHtml;
        }
        //this.geometrySVG = newHtml;
        //this.setGeometrySVG(newHtml);
    }

    drawGeometry(svg) {
        let g = Common.getNode("g", {});
        g.innerHTML = this.getGeometrySVG();
       
        svg.appendChild(g);
        return g; 
      //  let bb  = g.getBBox();
      //  g.innerHTML = g.innerHTML + '<rect fill="none" stroke="#000" x="'+bb.x+'" y="'+bb.y+'" width="'+bb.width+'" height="'+bb.height+'">';
        //g.transform.baseVal.initialize(svg.createSVGTransformFromMatrix(this.matrix));

    }

    calcBBox(svg){
        let g = Common.getNode("g", {});
        g.innerHTML = this.getGeometrySVG();
       
        svg.appendChild(g);
        
        let bb  = g.getBBox();
        svg.removeChild(g);
        this.bbox = bb;
    }

    prepare3Dface(w,h){
        let f = new Face3d();
        let eps = 0.0;
        f.p0 = new Point(this.bbox.x+eps , this.bbox.y+eps ,0);
        f.p1 = new Point((this.bbox.x + this.bbox.width)-eps*2 , this.bbox.y+eps ,0 );
        f.p2 = new Point(this.bbox.x+eps , (this.bbox.y+this.bbox.height)-eps*2 ,0);
        f.p3 = new Point((this.bbox.
            x + this.bbox.width)-eps*2 , (this.bbox.y+this.bbox.height)-eps*2 ,0 );
        f.normal = new Point(0,0,1);
        // if (this.fold){
        //     this.fold.point = new Point(this.fold.point.x/k, this.fold.point.y/k, this.fold.point.z/k);
        // }
        this.face3d = f;
        this.uvcoords =  `${f.p0.x/w} ${1-f.p0.y/h} ${f.p3.x/w} ${1-f.p3.y/h} ${f.p2.x/w} ${1-f.p2.y/h} ${f.p0.x/w} ${1-f.p0.y/h} ${f.p1.x/w} ${1-f.p1.y/h} ${f.p3.x/w} ${1-f.p3.y/h}`;

        
    }

    genBackside(id){
        let f = new Face3d();
        
        f.p0 = new Point(this.face3d.p0.x , this.face3d.p0.y , this.face3d.p0.z);
        f.p1 = new Point(this.face3d.p1.x , this.face3d.p1.y , this.face3d.p1.z);
        f.p2 = new Point(this.face3d.p2.x , this.face3d.p2.y , this.face3d.p2.z);
        f.p3 = new Point(this.face3d.p3.x , this.face3d.p3.y , this.face3d.p3.z);
        f.normal = new Point(-this.face3d.normal.x,-this.face3d.normal.y,-this.face3d.normal.z);
        this.face3dback = f;
    }

    bbox3d(){
        return Common.bboxFromArray([this.face3d.p0, this.face3d.p1, this.face3d.p2, this.face3d.p3]);
    }

    scale3d(k){
        this.bbox.x = this.bbox.x*k;
        this.bbox.y = this.bbox.y*k;
        this.bbox.width = this.bbox.width*k;
        this.bbox.height = this.bbox.height*k;
        if (this.fold){
            this.fold.point = new Point(this.fold.point.x*k, this.fold.point.y*k, this.fold.point.z*k);
        }
    }

    translate3d(dx, dy,dz){
        this.face3d.p0.x +=dx;
        this.face3d.p0.y +=dy;
        this.face3d.p0.z +=dz;

        this.face3d.p1.x +=dx;
        this.face3d.p1.y +=dy;
        this.face3d.p1.z +=dz;

        this.face3d.p2.x +=dx;
        this.face3d.p2.y +=dy;
        this.face3d.p2.z +=dz;

        this.face3d.p3.x +=dx;
        this.face3d.p3.y +=dy;
        this.face3d.p3.z +=dz;
    }

    rotate3d(fold){
        let theta = -(Math.PI/180)*fold.angle;
        let [tx,ty,tz] = Common.rotatePoint(this.face3d.p0.x,this.face3d.p0.y,this.face3d.p0.z,
            fold.point.x,fold.point.y, fold.point.z,
            fold.vector.x,fold.vector.y, fold.vector.z,theta
           );
           this.face3d.p0 = new Point(tx,ty,tz);

        [tx,ty,tz] = Common.rotatePoint(this.face3d.p1.x,this.face3d.p1.y,this.face3d.p1.z,
            fold.point.x,fold.point.y, fold.point.z,
            fold.vector.x,fold.vector.y, fold.vector.z,theta
           );
           this.face3d.p1 = new Point(tx,ty,tz);

        [tx,ty,tz] = Common.rotatePoint(this.face3d.p2.x,this.face3d.p2.y,this.face3d.p2.z,
            fold.point.x,fold.point.y, fold.point.z,
            fold.vector.x,fold.vector.y, fold.vector.z,theta
           );
           this.face3d.p2 = new Point(tx,ty,tz);

        [tx,ty,tz] = Common.rotatePoint(this.face3d.p3.x,this.face3d.p3.y,this.face3d.p3.z,
            fold.point.x,fold.point.y, fold.point.z,
            fold.vector.x,fold.vector.y, fold.vector.z,theta
           );
           this.face3d.p3 = new Point(tx,ty,tz);
        
        if (this.fold && this.fold != fold){
            
            let p2 = new Point(this.fold.point.x + this.fold.vector.x, this.fold.point.y + this.fold.vector.y,this.fold.point.z + this.fold.vector.z);
            let [px,py,pz] = Common.rotatePoint(this.fold.point.x,this.fold.point.y,this.fold.point.z,
                fold.point.x,fold.point.y, fold.point.z,
                fold.vector.x,fold.vector.y, fold.vector.z,theta
               );

            let [p2x,p2y,p2z] = Common.rotatePoint(p2.x,p2.y,p2.z,
                fold.point.x,fold.point.y, fold.point.z,
                fold.vector.x,fold.vector.y, fold.vector.z,theta
               );
            //this.fold.point = new Point(tx,ty,tz);


            // let [vx,vy,vz] = Common.rotatePoint(this.fold.vector.x,this.fold.vector.y,this.fold.vector.z,
            //     fold.point.x,fold.point.y, fold.point.z,
            //     fold.vector.x,fold.vector.y, fold.vector.z,theta
            //    );
            let [nx,ny,nz] = Common.rotatePoint(this.face3d.normal.x,this.face3d.normal.y,this.face3d.normal.z,
                fold.point.x,fold.point.y, fold.point.z,
                fold.vector.x,fold.vector.y, fold.vector.z,theta
               );

               

            let [nx2,ny2,nz2] = Common.rotatePoint(0,0,0,
            fold.point.x,fold.point.y, fold.point.z,
            fold.vector.x,fold.vector.y, fold.vector.z,theta
            );

            
            this.face3d.normal = new Point(nx2-nx,ny2-ny,nz2-nz);
            
            this.fold.point = new Point(px,py,pz);
            this.fold.vector = new Point(p2x - px,p2y-py,p2z-pz);

        }   
    }

}
